@each $colorClass, $color in $theme-colors {
	.bg-#{$colorClass} {
		&.bg-lighten,
		&.bg-lighten-5 {
			background-color: lighten($color, 5%) !important;
		}

		&.bg-lighten-10 {
			background-color: lighten($color, 10%) !important;
		}

		&.bg-lighten-15 {
			background-color: lighten($color, 15%) !important;
		}

		&.bg-lighten-20 {
			background-color: lighten($color, 20%) !important;
		}

		&.bg-lighten-25 {
			background-color: lighten($color, 25%) !important;
		}

		&.bg-lighten-30 {
			background-color: lighten($color, 30%) !important;
		}

		&.bg-lighten-35 {
			background-color: lighten($color, 35%) !important;
		}

		&.bg-lighten-40 {
			background-color: lighten($color, 40%) !important;
		}

		&.bg-lighten-45 {
			background-color: lighten($color, 45%) !important;
		}

		&.bg-lighten-50 {
			background-color: lighten($color, 50%) !important;
		}

		&.bg-darken,
		&.bg-darken-5 {
			background-color: darken($color, 5%) !important;
		}

		&.bg-darken-10 {
			background-color: darken($color, 10%) !important;
		}

		&.bg-darken-15 {
			background-color: darken($color, 15%) !important;
		}

		&.bg-darken-20 {
			background-color: darken($color, 20%) !important;
		}

		&.bg-darken-25 {
			background-color: darken($color, 25%) !important;
		}

		&.bg-darken-30 {
			background-color: darken($color, 30%) !important;
		}

		&.bg-darken-35 {
			background-color: darken($color, 35%) !important;
		}

		&.bg-darken-40 {
			background-color: darken($color, 40%) !important;
		}

		&.bg-darken-45 {
			background-color: darken($color, 45%) !important;
		}

		&.bg-darken-50 {
			background-color: darken($color, 50%) !important;
		}
	}
}
