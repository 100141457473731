@import 'styles/theme-colors';

table.table-stripe {
	$background-color: theme-color("gray-100");
	$border: 1px solid theme-color("gray-200");
	$paddingX: 1rem;
	$paddingY: .5rem;
	$transition: background-color .15s ease;

	margin-bottom: 0;

	* {
		&[class^=border] {
			border-color: theme-color("gray-200") !important;
		}
	}

	thead {
		tr {
			th {
				color: theme-color("gray-600");
				background-color: $background-color;
				border-top: $border;
				border-bottom: $border;
				padding-left: $paddingX;
				padding-right: $paddingX;
				font-size: .7rem;
				font-weight: 500;
				text-transform: uppercase;
				letter-spacing: 1px;
				white-space: nowrap;
				padding-top: .5rem;
				padding-bottom: .5rem;

				&.hasSort {
					cursor: pointer;
					transition: $transition;

					&:hover {
						background-color: darken($background-color, 1%);
					}
				}
			}
		}
	}

	tbody {
		tr {
			cursor: pointer;
			transition: $transition;

			&:hover {
				background-color: $background-color;
			}

			&:first-child {
				th {
					border-top: 0;
				}
			}

			th, td {
				vertical-align: middle;
				padding: $paddingY $paddingX;
				font-size: .9rem;
			}

			td {

			}
		}
	}
}
