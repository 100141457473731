$theme-colors: (
  "primary": #5e72e4,
  "primary-dark": darken(#5e72e4, 10%),
  "secondary": #6c757d,
  "success": #2dce89,
  "success-dark": #29b579,
  "danger": #f5365c,
  "danger-dark": #c62828,
  "warning": #fb6340,
  "warning-light": #f57f17,
  "info": #11cdef,
  "info-dark": darken(#11cdef, 5%),
  "light": #f4f5f7,
  "dark": #172b4d,
  "white": #ffffff,
  "transparent": transparent,
  "gray-100": #f6f9fc,
  "gray-200": #e9ecef,
  "gray-300": #dee2e6,
  "gray-400": #ced4da,
  "gray-500": #adb5bd,
  "gray-600": #8898aa,
  "gray-700": #525f7f,
  "gray-800": #32325d,
  "gray-900": #212529,
);

@function theme-color($key: "primary") {
  @return map-get($theme-colors, $key);
}
