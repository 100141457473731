@import 'styles/theme-colors';

.Toggle {
	background-color: theme-color("gray-200");
	border-radius: 6px;
	padding: 2px;
	border: 1px solid theme-color("gray-300");

	.Option {
		background-color: theme-color("gray-200");
		color: theme-color("gray-600");
		border-radius: 4px;
		padding: 1px .3rem;

		text-transform: capitalize;
		cursor: pointer;

		transition: background-color .15s ease, color .15s ease;

		&:hover {
			background-color: theme-color("gray-100");
		}

		&:not(:last-child) {
			margin-right: .3rem;
		}

		&.active {
			background-color: white;
			color: theme-color("gray-800");
		}
	}
}
