@import 'styles/theme-colors';

.BackBtn {
	.icon {
		font-size: .85rem;
		margin-left: .25rem;
		margin-right: .25rem;
		transition:
			margin-left 0.2s ease,
			margin-right 0.2s ease,
			color 0.2s ease;
	}

	.text {
		transition: color 0.2s ease;
	}

	&:hover {
		cursor: pointer;

		.icon {
			margin-left: 0;
			margin-right: .5rem;
			color: theme-color("info-dark");
		}

		.text {
			color: theme-color("info-dark");
		}
	}
}
